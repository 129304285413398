@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply font-bold py-2 px-3 rounded border border-transparent leading-5;
}

.btn-yellow {
    @apply bg-yellow-500;
}
.btn-yellow:hover {
    @apply bg-yellow-600;
}

.btn-red {
    @apply bg-red-500;
}
.btn-red:hover {
    @apply bg-red-600;
}

.field {
    @apply appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 text-gray-900 rounded leading-5;
}

.field:focus {
    @apply outline-none border-blue-300 z-10;
}
